import React from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { Button, CardActionArea, CardActions } from '@mui/material';


const GameSetupTile = (props) => {
    return (
      <Grid md={3} xs={6} marginBottom={5}>
        <Tooltip title={props.tooltip}>
          <Card sx={{ maxWidth: 345, border: 1, borderColor: 'primary.main' }} variant="outlined" onClick={() => props.handleDomainSelect(props.id)}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="100vw"
                image={props.img}
                alt="green iguana"
              />
              <CardContent className="setup--cardcontent">
                <Typography variant="h6" component="div" align="center" className='setup--descriptor'>
                  {props.name}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Tooltip>
      </Grid>
    );
  }

  
  
  export default GameSetupTile


  // const GameSetupTile = (props) => {
  //   return (
  //     <div className='setup--tile' onClick={() => props.handleDomainSelect(props.id)}>
  //         <div className='setup--icon'>
  //           <img src = {props.img} className="setup--image"/>
  //         </div>
  //         <h2 className='setup--descriptor'>{props.name}</h2>
  //     </div>
  //   )
  // }
  
  // return (
  //   <Grid xs={3} onClick={() => props.handleDomainSelect(props.id)}>
  //       <img src = {props.img} className="setup--image"/>
  //       <h2 className='setup--descriptor'>{props.name}</h2>
  //   </Grid>
  // )