import React from 'react'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Geographies from '../data/geographies.json';

function LocationBar(props) {

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    // console.log(string, results)
  }

  const handleOnHover = (result) => {
    // the item hovered
    // console.log(result)
  }

//   const handleOnSelect = (item) => {
//     // the item selected
//     console.log(item)
//   }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const formatResult = (item) => {
    return (
      <>
        <span style={{textAlign: 'left'}}>{item.name} </span>
      </>
    )
  }

  return (
    <div className="App">
      <header className="App-header">
        <div style={{ width: 400, marginLeft : "auto", marginRight : "auto"}}>
          <ReactSearchAutocomplete
            items={Geographies}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={props.handleOnSelect}
            onFocus={handleOnFocus}
            autoFocus
            formatResult={formatResult}
            maxResults = {5}
            styling={
                {
                  position: "absolute",
                  zIndex: "99",
                  border: "1.5px solid #196224",                
                }
              }
          />
        </div>
      </header>
    </div>
  )
}

export default LocationBar