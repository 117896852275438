import React, { Component } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TaxaLookup from '../data/taxa_lookup_us';
import GuessBar from '../components/GuessBar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';




function ComboBox(props) {
  return (
    <Autocomplete
    value={props.guessValue}
    onChange={(event: any, newValue: string | null) => {
      props.setGuessValue(newValue);
    }}
    inputValue={props.inputValue}
    onInputChange={(event, newInputValue) => {
      props.setInputValue(newInputValue);
    }}
      id="combo-box-demo"
      options={TaxaLookup}
      sx={{ width: 600 }}
      renderInput={(params) => <TextField {...params} label="Your guess here" />}
    />
  );
}

{/* <ComboBox guessValue={props.guessValue} setGuessValue={props.setGuessValue} inputValue={props.inputValue} setInputValue={props.setInputValue}/> */}

export default function Guessing(props) {

  return (
    <div>
      <Grid container rowSpacing={0} columnSpacing={0} backgroundColor="#EEECD6" paddingLeft="2vw" paddingRight="2vw" columns={16}>
        <Grid md={10} sm={13} xs={13} paddingBottom={1} paddingTop={1}>
            <GuessBar handleOnSelect={props.handleOnSelect}/>
        </Grid>
        <Grid md={2} sm={3} xs={3} paddingBottom={1} paddingTop={1} paddingLeft={1} alignItems="center" display="flex">
          {JSON.stringify(props.guessValue) === '{}' ?
              <Button variant="contained" color="error" className="guess--button" borderRadius="100px" size="medium">Guess</Button>      
                :
                <Button variant="contained" className="guess--button" borderRadius="100px" size="medium" onClick={() => props.submitGuess(props.correctSpecies, props.guessValue)}>Guess</Button>      
              }
        </Grid>
        <Grid md={2} sm={0} xs={0} paddingBottom={1} paddingTop={1}>
          <Stack spacing={0} alignItems='center' display={{ xs: "none", sm: 'none', md: 'block'}}>
            <Typography variant="subtitle2" component="div" align="center" className='setup--descriptor'>ROUND</Typography>
            <Typography variant="h6" component="div" align="center" className='setup--descriptor'>{props.roundNum} of 5</Typography>
          </Stack>
        </Grid>
        <Grid md={2} sm={0} xs={0} paddingBottom={1} paddingTop={1}>
          <Stack spacing={0} alignItems='center' display={{ xs: "none", sm: 'none', md: 'block'}}>
            <Typography variant="subtitle2" component="div" align="center" className='setup--descriptor'>SCORE</Typography>
            <Typography variant="h6" component="div" align="center" className='setup--descriptor'>{props.score.toLocaleString()}</Typography>
          </Stack>
        </Grid>
      </Grid>
    <Container maxWidth="false" style={{backgroundColor: "#2E2E2E"}}>
      <Box backgroundColor="red" height="81vh">
        <img src = {props.imageURL} className = 'guess--img'></img>
      </Box>
    </Container>
    {/* {props.apiSuccess? <img src = {props.imageURL} className = 'guess--img'></img>: <CircularProgress/>} */}
    </div>
  )
}

{/* <Box position="absolute" bottom="0px" backgroundColor="red" display= "flex"> */}
