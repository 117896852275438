import React from "react"
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'


export default function GameRecap(props) {
    return (
        <div>
        <Container maxWidth="lg">
        <Typography variant="h5" align="center" className="header--large" paddingBottom={3} paddingTop={5} gutterBottom>GREAT GAME!</Typography>
            <Grid container columnSpacing={3} backgroundColor="background">
                <Grid md={2} xs={0}></Grid>
                <Grid md={4} xs={5}>
                        <Typography variant="subtitle1" align="center" className="header--large" paddingBottom={2} paddingTop={2}>GAME SCORE</Typography>
                        <Box textAlign='center' sx={{ border: 2, borderColor: 'primary.main', backgroundColor: 'white', borderRadius : 2}}>
                            <Typography variant="h6" align="center" className='setup--descriptor' paddingBottom={2} paddingTop={2}>{props.gameScore.toLocaleString()}</Typography>
                        </Box>
                </Grid>
                <Grid md={4} xs={5}>
                        <Typography variant="subtitle1" align="center" className="header--large" paddingBottom={2} paddingTop={2}>SCORE RANK</Typography>
                        <Box textAlign='center' sx={{ border: 2, borderColor: 'primary.main', backgroundColor: 'white', borderRadius : 2}}>
                            <Typography variant="h6" align="center" className='setup--descriptor' paddingBottom={2} paddingTop={2}>Coming Soon</Typography>
                        </Box>
                </Grid>
                <Grid md={2} xs={0}></Grid>
            </Grid>
        <Box textAlign='center' marginTop={5}>
            <Button variant="contained" className="guess--button" borderRadius="100px" size="large" onClick={props.newGame}>Play Again</Button>
          </Box>
          </Container>
        </div>
    )
}





// export default function GameRecap(props) {
//     return (
//         <div>
//             <h2>You Scored {props.gameScore} points!</h2>
//             <h3>Game Length: {props.numRounds}</h3>
//             <button onClick={props.newGame}>Play Again</button>
//         </div>
//     )
// }