import React from 'react'
import GameSetupTile from '../components/GameSetupTile'
import Geographies from '../data/geographies';
import LocationBar from '../components/LocationBar';
import SetupFamilies from '../data/setupfamilies';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'



// const gameDomains = SetupFamilies.map(tile => <GameSetupTile name={tile.name} handleDomainSelect={props.handleDomainSelect} />)

const GameSetup = (props) => {

  const gameDomains = SetupFamilies.map(tile => <GameSetupTile name={tile.name} id={tile.id} img={tile.image} handleDomainSelect={props.handleDomainSelect} tooltip={tile.tooltip} />)


  return (
    <div>
    <Container maxWidth="lg">
          <Typography variant="h5" align="center" className="header--large" paddingBottom={3} paddingTop={5} gutterBottom>CHOOSE YOUR LOCATION</Typography>
          <Box textAlign='center'>
            <LocationBar handleOnSelect={props.handleLocationSelect}/>
          </Box>
          <Typography variant="h5" align="center" className="header--large" paddingBottom={3} paddingTop={5} gutterBottom>CHOOSE YOUR DOMAIN</Typography>
          {/* <h1 className='header--large'>Choose Your Game</h1> */}
          <Grid container spacing={3}>
              {gameDomains}
          </Grid>
          <Box textAlign='center'>
            <Button variant="contained" className="guess--button" borderRadius="100px" size="large" onClick={props.startGame}>Start Game</Button>
          </Box>
    </Container>
    </div>
    
  )
}

export default GameSetup


// return (
//   <div className='setup--container'>
//   <h1 className='header--large'>Choose Your Game</h1>
//   <div className='setup--grid'>
//       {gameDomains}
//   </div>
//   <h1 className='header--large'>Choose Your Location</h1>
//   <LocationBar handleOnSelect={props.handleLocationSelect}/>
//   <Button variant="outlined" className="guess--button" onClick={props.startGame}>Start Game</Button>
// </div>

// )