import React from "react"
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Tooltip from "@mui/material/Tooltip";
import NoImage from '../images/no_image.jpg'
import { CardActionArea, CardActions, CardHeader } from '@mui/material';

export default function Scoring(props) {
    console.log(props.guessCredit)
    console.log(props.correctCredit)
    return (
        <div>
        <Container maxWidth="lg">
            <Grid container columnSpacing={3} backgroundColor="background">
                <Grid md={2} xs={0}></Grid>
                <Grid md={4} xs={5}>
                    <Card elevation={0} style={{backgroundColor: "#EEECD6"}}>
                            <Typography variant="subtitle2" align="center" className="header--large" paddingBottom={2} paddingTop={2}>YOUR GUESS</Typography>
                            <Tooltip title={props.guessCredit} placement="bottom">
                                <CardMedia
                                component="img"
                                height="200vw"
                                image={props.guessPhoto === "" ? NoImage : props.guessPhoto}
                                alt="green iguana"
                                sx = {{borderRadius : 2, border: 2, borderColor: 'primary.main'}}
                                />
                            </Tooltip>
                            <CardContent>
                                <Typography variant="h6" component="div" align="center" className='setup--descriptor' textTransform='capitalize'>{props.guessValue.name}</Typography>
                                <Typography variant="caption" component="div" align="center" className='setup--descriptor' fontStyle='italic'>{props.guessValue.scientificName}</Typography>
                            </CardContent>
                    </Card>
                </Grid>
                <Grid md={4} xs={5}>
                <Card elevation={0} style={{backgroundColor: "#EEECD6"}}>
                            <Typography variant="subtitle2" align="center" className="header--large" paddingBottom={2} paddingTop={2}>CORRECT SPECIES</Typography>
                            <Tooltip title={props.correctCredit} placement="bottom">
                                <CardMedia
                                component="img"
                                height="200vw"
                                image={props.correctImage}
                                alt="green iguana"
                                sx = {{borderRadius : 2, border: 2, borderColor: 'primary.main'}}
                                />
                            </Tooltip>
                            <CardContent>
                                <Typography variant="h6" component="div" align="center" className='setup--descriptor' textTransform='capitalize'>{props.correctSpecies}</Typography>
                                <Typography variant="subtitle1" component="div" align="center" className='setup--descriptor' fontStyle='italic'>{props.correctSpeciesScientific}</Typography>
                            </CardContent>
                    </Card>
                </Grid>
                <Grid md={2} xs={0}></Grid>
            </Grid>
            <Grid container columnSpacing={3} backgroundColor="background">
                <Grid md={2} xs={0}></Grid>
                <Grid md={4} xs={5}>
                        <Typography variant="subtitle1" align="center" className="header--large" paddingBottom={2} paddingTop={2}>MATCH LEVEL</Typography>
                        <Box textAlign='center' sx={{ border: 2, borderColor: 'primary.main', backgroundColor: 'white', borderRadius : 2}}>
                            <Typography variant="h6" align="center" className='setup--descriptor' paddingBottom={2} paddingTop={2}>{props.matchLevel}</Typography>
                        </Box>
                </Grid>
                <Grid md={4} xs={5}>
                        <Typography variant="subtitle1" align="center" className="header--large" paddingBottom={2} paddingTop={2}>ROUND SCORE</Typography>
                        <Box textAlign='center' sx={{ border: 2, borderColor: 'primary.main', backgroundColor: 'white', borderRadius : 2}}>
                            <Typography variant="h6" align="center" className='setup--descriptor' paddingBottom={2} paddingTop={2}>{props.score.toLocaleString()}</Typography>
                        </Box>
                </Grid>
                <Grid md={2} xs={0}></Grid>
            </Grid>
        <Box textAlign='center' marginTop={5}>
            <Button variant="contained" className="guess--button" borderRadius="100px" size="large" onClick={props.nextRound}>{props.roundNum === props.numRounds ? "See Game Recap" : "Next Round"}</Button>
          </Box>
          </Container>
        </div>
    )
}


// export default function Scoring(props) {
//     return (
//         <div>
//             <h2>Correct Species: {props.correctSpecies}</h2>
//             <h2>Your Guess: {props.guessValue.name}</h2>
//             <h2>Your round score is {props.score}</h2>
//             <h2>You matched at the {props.matchLevel} level</h2>
//             {props.roundNum === props.numRounds ?
//             <button onClick={props.nextRound}>See Game Recap</button>
//             :
//             <button onClick={props.nextRound}>Next Round</button>
//             }
//             <h3>"{"Test" && JSON.stringify(props.guessAncestors)}</h3>
//         </div>
//     )
// }

// export default function Scoring(props) {
//     return (
//         <div>
//         <Container maxWidth="lg" style={{backgroundColor: "#2E2E2E"}}>
//             <Grid container spacing={3} backgroundColor="pink">
//                 <Grid md={6} xs={12} paddingBottom={1} paddingTop={1}>
//                     <Stack spacing={0} alignItems='center'>
//                         <Typography variant="h5" align="center" className="header--large" paddingBottom={3} paddingTop={5} gutterBottom>YOUR GUESS</Typography>
//                         <Box backgroundColor="red" height="30vh">
//                             <img src = "https://inaturalist-open-data.s3.amazonaws.com/photos/30244929/large.jpg" className='scoring--img'></img>
//                         </Box>
//                         <Typography variant="h6" component="div" align="center" className='setup--descriptor'>Commmon Milkweed</Typography>
//                         <Typography variant="h6" component="div" align="center" className='setup--descriptor' fontStyle='italic'>Lorem Ipsum</Typography>
//                     </Stack>
//                 </Grid>
//                 <Grid md={6} xs={12} paddingBottom={1} paddingTop={1}>
//                     <Stack spacing={0} alignItems='center'>
//                         <Typography variant="h5" align="center" className="header--large" paddingBottom={3} paddingTop={5} gutterBottom>CORRECT SPECIES</Typography>
//                         <Box backgroundColor="red" height="30vh">
//                             <img src = "https://inaturalist-open-data.s3.amazonaws.com/photos/30244929/large.jpg" className='scoring--img'></img>
//                         </Box>                        
//                         <Typography variant="h6" component="div" align="center" className='setup--descriptor'>Commmon Milkweed</Typography>
//                         <Typography variant="h6" component="div" align="center" className='setup--descriptor' fontStyle='italic'>Lorem Ipsum</Typography>
//                     </Stack>
//                 </Grid>
//             </Grid>
//         </Container>
//         </div>
//     )
// }