import React from 'react'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Box from '@mui/material/Box'
import TaxaLookup from '../data/taxa_lookup_us';

function GuessBar(props) {

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    // console.log(string, results)
  }

  const handleOnHover = (result) => {
    // the item hovered
    // console.log(result)
  }

//   const handleOnSelect = (item) => {
//     // the item selected
//     console.log(item)
//   }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const formatResult = (item) => {
    return (
      <>
        <span style={{textAlign: 'left' }}>{item.name} </span><span style={{textAlign: 'left', fontStyle: 'italic'}}>{item.scientificName}</span>
      </>
    )
  }

  return (
          <ReactSearchAutocomplete
            items={TaxaLookup}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={props.handleOnSelect}
            onFocus={handleOnFocus}
            autoFocus
            formatResult={formatResult}
            placeholder="Your Guess Here"
            maxResults={10}
            inputDebounce={400}
            styling={{
              border: "1.5px solid #196224"
            }}
            fuseOptions = {{
                threshold: 0.4,
                ignoreLocation: true,
                keys: [
                  "name",
                  "scientificName",
                ]
            }}
          />
  )
}

export default GuessBar