import React from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Logo from '../images/logo_beta.png'

const Header = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
    <AppBar position="static" elevation={0} color="secondary" height="10vh">
      <Toolbar>
      <Box
            component="img"
            sx={{
            height: "2.5em",
            }}
            alt="Your logo."
            src={Logo}            
        />
      </Toolbar>
    </AppBar>
  </Box>
  )
}

export default Header

// const Header = () => {
//   return (
//     <nav>
//         <h1 className='nav--logo'>TAXUH</h1>
//     </nav>
//   )
// }

// palette: {
//   primary: {
//     main: '#196224',
//   },
//   secondary: {
//     main: '#EEECD6',
//   },
//   info: {
//     main: '#273526',
//   },
//   background: {
//     main: '#273526',
//   },
// },