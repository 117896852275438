import React, { Component, useState, useEffect, useRef } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Header from "./components/Header";
import GameSetup from './pages/GameSetup';
import Guessing from './pages/Guessing';
import Scoring from './pages/Scoring';
import GameRecap from './pages/GameRecap';
import GuessBar from './components/GuessBar';
import apiResponse from './data/apiresponse';
import ancestry from './data/ancestry'
import ancestor_lookup from './data/ancestor_lookup'
import TaxaLookup from './data/taxa_lookup_us';
import { createTheme, ThemeProvider } from '@mui/material';
import { Analytics } from '@vercel/analytics/react';
import axios from "axios";
import { sql } from '@vercel/postgres';
import './App.css';


const theme = createTheme({
  typography: {
    fontFamily: [
      'Inter',
      'Roboto',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#196224',
    },
    secondary: {
      main: '#EEECD6',
    },
    info: {
      main: '#273526',
    },
    background: {
      main: '#2E2E2E',
    },
    error: {
      main: '#CDCDCD',
    },
  },
  shape: {
    borderRadius: 10,
  },
  // container: {
  //   flexGrow: 1,
  //   minHeight: '100vh',
  // },
  // root: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   minHeight: '100vh', // Set minimum height to 100% of the viewport height
  // },
});

function App() {

  function randomDate(start, end) {
      const d1 = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))
      const d1String = d1.getFullYear()+'-'+d1.getMonth()+'-'+d1.getDate()
      const d2String = d1.getFullYear()+'-'+d1.getMonth()+3+'-'+d1.getDate()
      return [d1String, d2String]

  }

  const initialRender = useRef(true);
  const [guessed, setGuessed] = React.useState(false);
  const [guessPlaced, setGuessPlaced] = React.useState(false);
  const [observations, setObservations] = useState({})
  const [apiSuccess, setApiSuccess] = useState(false)
  const [guessValue, setGuessValue] = React.useState({});
  const [inputValue, setInputValue] = React.useState('');
  const [score, setScore] = React.useState(0);
  const [configured, setConfigured] = React.useState(false);
  const [scoringComplete, setScoringComplete] = React.useState(false);
  const [gameUpdateComplete, setGameUpdateComplete] = React.useState(false);
  const [setupComplete, setSetupComplete] = React.useState(false);
  const [apiCallCount, setApiCallCount] = React.useState(0);
  const [startTime, setStartTime] = React.useState(new Date());


  console.log({
    POSTGRES_URL: process.env.POSTGRES_URL,
    POSTGRES_URL_NON_POOLING: process.env.POSTGRES_URL_NON_POOLING,
    "Env Test" : process.env.REACT_APP_TEST,
  });

  console.log('~~~~~~~~~~~~~~~~~~~~~ POSTING GAME RESULTS  ~~~~~~~~~~~~~~~~~~~~~~')
  
  try {
    sql`INSERT INTO game (universe, num_rounds, score) VALUES (1234, 5, 500);`;
  } catch (error) {
    console.log(error);
  }
  

  // console.log({
  //   "Test" : "Testing Postgres URLs",
  //   POSTGRES_URL: process.env.POSTGRES_URL,
  //   POSTGRES_URL_NON_POOLING: process.env.POSTGRES_URL_NON_POOLING,
  //   "Env Test" : process.env.REACT_APP_TEST,
  //   "All" : proccess.env,
  // });


  const [randArr, setRandArr] = useState(
    Array.from({ length: 20 }, (_, i) => i + 1).sort(() => Math.random() - 0.5).slice(0, 6)
  );

  // console.log("------Random Array------")
  // console.log(randArr)

  const [round, setRound] = React.useState({
    roundNum:1,
    prompt :  null,
    prompt_ancestors :  {},
    guess : {},
    guess_ancestors: {},
    guess_photo: '',
    roundScore: 0,
    matchLevel: '',
  });

  const [game, setGame] = React.useState({
    numRounds : 5,
    gameScore: 0,
    rounds : {},
    location : 1,
    domain : 47126,
  });

  const fetchData = async () => {
    const [d1, d2] = randomDate(new Date(2018, 0, 1), new Date())
    console.log("Dates")
    console.log(d1)
    console.log(d2)
    const response = await fetch(`https://api.inaturalist.org/v1/observations?photos=true&place_id=${game.location}&rank=species&taxon_id=${game.domain}&per_page=50&d1=${d1}&d2=${d2}&photo_license=cc-by%2Ccc-by-nc%2Ccc-by-nd%2Ccc-by-sa%2Ccc-by-nc-nd%2Ccc-by-nc-sa%2Ccc0&order=desc&order_by=created_at`)
    setApiCallCount(prevValue => prevValue + 1)
    if (!response.ok) {
      throw new Error('Data coud not be fetched!')
    } else {
      return response.json()
    }
  }

  useEffect(() => {
    // call the function
    if (configured) {
      console.log("-----STARTING API QUERY ---------")
      fetchData()
        .then((res) => {
          console.log(["Total Results", res.total_results])
          setObservations(res)
          setApiSuccess(true)
          console.log('---API QUERY COMPLETE----')
        })
        .catch((e) => {
          console.log(e.message)
        })
    } else {
      console.log("---- CANNOT START API, GAME NOT CONFIGURED ------");   
    } 
  }, [configured])

  

  
  // const image_url = observations ? round.prompt.observation_photos[0].photo.url.replace('square','medium') : ''
  // console.log("Has API Been Called?")
  // console.log(apiSuccess)
  // console.log(round.prompt)
  const image_url = round.prompt ? round.prompt.observation_photos[0].photo.url.replace('square','medium') : ''
  const correctSpecies = round.prompt ? round.prompt.taxon.preferred_common_name : ''
  const correctSpeciesScientific = round.prompt ? round.prompt.taxon.name : ''
  // console.log(image_url)

  async function fetchAncestors(ancestor_array) {
    const ancestor_list_string = ancestor_array.join("%2C")
    let response = await fetch(`https://api.inaturalist.org/v1/taxa/${ancestor_list_string}`);
    setApiCallCount(prevValue => prevValue + 1)
    if (!response.ok) {
      throw new Error('Data coud not be fetched!')
    } else {
      return response.json()
    }
  }

  async function getTaxaInfo(taxa_id) {
    let response = await fetch(`https://api.inaturalist.org/v1/taxa/${taxa_id}`);
    setApiCallCount(prevValue => prevValue + 1)
    if (!response.ok) {
      throw new Error('Data coud not be fetched!')
    } else {
      return response.json()
    }
  }


  useEffect(() => {

    if (apiSuccess) {
      console.log("###### Game Setup Complete ########")
      console.log(observations.results)
      setRound(prevRound =>  {
        return {
          ...prevRound,
          prompt : observations.results[randArr[prevRound.roundNum]],
          //guess_placed : !prevRound.guess_placed
        }
      }
      )
      setSetupComplete(true)
    } else {
      console.log("----GAME SETUP WAITING ON API-------");   
    }
  }, [apiSuccess])

    function submitGuess() {     

    //  setRound(prevRound =>  {
    //   return {
    //     ...prevRound,
    //     roundNum : prevRound.roundNum + 1, 
    //     //guess_placed : !prevRound.guess_placed
    //   }
    // })
    setGuessPlaced(prevValue => !prevValue)
  }


  // Get Ancestors for Prompt

  console.log(round)

  useEffect(() => {
    // const ancestor_list_string = round.prompt ? round.prompt.taxon.ancestor_ids : 'NULL'

    async function sequentialStart() {
      console.log("==SEQUENTIAL START PROMPT ANCESTORS ==");
    
      // const ancestors_info = await fetchAncestors(ancestor_list_string);
      // console.log(ancestors_info); // 3. this runs 3 seconds after 1.

      // const guess_ancestry = {};

      // ancestors_info.results.forEach((ancestor) => {
      //     guess_ancestry[ancestor.rank] = ancestor.id;
      // });

      const guess_ancestry = ancestor_lookup.filter((entry) => entry.id === round.prompt.taxon.id)[0]

      console.log(guess_ancestry)
      // console.log(ancestor_lookup.filter((entry) => entry.id === round.prompt.taxon.id))

      await setRound(prevRound =>  {
        return {
          ...prevRound,
          prompt_ancestors : guess_ancestry
        }
      })

      console.log("Prompt Ancestors Updated: ", round.prompt_ancestors)

    }  

      // call the function
      if (round.prompt) {
        console.log(round.prompt);
        console.log("Starting Prompt Ancestors");
        sequentialStart();
      } else {
      console.log("Not Starting Prompt Ancestors");    
    }
  
    // // call the function
    // sequentialStart()
  }, [setupComplete, gameUpdateComplete])

  // useEffect(() => {
  //   fetchAncestors(round.prompt.taxon.ancestor_ids)
  //     .then((res) => {
  //       //console.log(res.results)
  //       const guess_ancestry = {};

  //       res.results.forEach((ancestor) => {
  //         guess_ancestry[ancestor.rank] = ancestor.id;
  //     });
  //     setRound(prevRound =>  {
  //       return {
  //         ...prevRound,
  //         prompt_ancestors : guess_ancestry
  //       }
  //     })
  //     console.log("Prompt Ancestors: ", round.prompt_ancestors)
  //     })
  //     .catch((e) => {
  //       console.log(e.message)
  //     })


  // }, [])

  

  useEffect(() => {
    // const ancestor_list_string = round.prompt.taxon.ancestor_ids.join("%2C")
    
    async function sequentialStart() {
      console.log("=====SEQUENTIAL START GUESS ANCESTORS======");

      // 1. Execution gets here almost instantly
      // const taxa_info = await getTaxaInfo(guessValue.id);
      // console.log(taxa_info); // 2. this runs 2 seconds after 1.

      // console.log("++++++FINDING TAXA+++++++")
      // console.log(taxa_info.results[0].ancestor_ids.concat([guessValue.id]))
    
      // const ancestors_info = await fetchAncestors(taxa_info.results[0].ancestor_ids.concat([guessValue.id]));
      // console.log(ancestors_info);

      // const guess_ancestry = {};

      // ancestors_info.results.forEach((ancestor) => {
      //     guess_ancestry[ancestor.rank] = ancestor.id;
      // });

      // console.log(guess_ancestry)

      const guess_ancestry = ancestor_lookup.filter((entry) => entry.id === guessValue.id)[0]

      console.log(guess_ancestry)

      console.log("==START SCORING ==");

      let round_score = 0
      let match_level = ''
    
        console.log(round.prompt_ancestors)
    
        if (guess_ancestry['species']) {
          if (guess_ancestry['species'] === round.prompt_ancestors['species']) {
            round_score = 20000;
            match_level = "Species"
          } else if(guess_ancestry['genus'] === round.prompt_ancestors['genus']) {
            round_score = 10000;
            match_level = "Genus"
          } else if(guess_ancestry['family'] === round.prompt_ancestors['family']) {
            round_score = 5000;
            match_level = "Family"
          } else if(guess_ancestry['order'] === round.prompt_ancestors['order']) {
            round_score = 2500;
            match_level = "Order"
          } else if(guess_ancestry['class'] === round.prompt_ancestors['class']) {
            round_score = 1200;
            match_level = "Class"
          } else if(guess_ancestry['phylum'] === round.prompt_ancestors['phylum']) {
            round_score = 500;
            match_level = "Phylum"
          } else {
            round_score = 0;
            match_level = "None"
          }
        } else {
          console.log('NO SPECIES YET')
        }
    
        console.log(round_score)
        console.log(match_level)

      
      // console.log("Pause 1")
      // await new Promise(r => setTimeout(r, 1000))

      const guess_photo_result = TaxaLookup.filter((entry) => entry.id === guessValue.id)[0].image_url
      console.log(guess_photo_result)


      await setRound(prevRound =>  {
        return {
          ...prevRound,
          guess: guessValue,
          guess_ancestors : guess_ancestry,
          guess_photo: guess_photo_result,
          roundScore : round_score,
          matchLevel: match_level
        }
      })

      await setGame(prevGame => ({
        ...prevGame,
        gameScore : prevGame.gameScore + round_score
      }))



      console.log(round)
      console.log(game)


      // console.log("Pause 2")
      // await new Promise(r => setTimeout(r, 1000))

      console.log("Trying to set ScoringComplete to True")

      await setScoringComplete(true)

      console.log(scoringComplete)

      // setGame(prevGame => ({
      //     ...prevGame,
      //     rounds: {...prevGame.rounds, 
      //       [round.roundNum - 1] : {
      //         ...round,
      //         guess: taxa_info.results[0],
      //         guess_ancestors : guess_ancestry,
      //         roundScore : round_score,
      //         matchLevel: match_level
      //       }
      //     }
      //   }))

      // console.log("GAME UPDATED")

      // console.log(game)

    }  

    // call the function
    if (initialRender.current) {
      initialRender.current = false;
    } else if  (guessPlaced === true) {
    sequentialStart();    
    } else {
      console.log("Guess not placed, not scoring guess")
    }
    
  }, [guessPlaced])


  useEffect(() => {
    async function sequentialGameUpdate() {

      await console.log("Scoring Complete, STARTING TO UPDATE GAME")

        await setGame(prevGame => ({
            ...prevGame,
            rounds: {...prevGame.rounds, 
              [round.roundNum] : round
            }
          }))

        // let calc_rd_score = 0

        // for (const val in game.rounds) {
        //   console.log(game.rounds[val].roundScore) ;
        //   calc_rd_score = calc_rd_score + game.rounds[val].roundScore;
        // }

      await console.log("Scoring Complete, GAME UPDATED")

      await setGameUpdateComplete(true)

    }  

    // call the function
    
    if (scoringComplete === true) {
      sequentialGameUpdate();
    } else {
      console.log('Scoring not Complete')
    }
        
  }, [scoringComplete])

  useEffect(() => {
    console.log("###### VARIABLE CHECK ########")
    console.log(round);
    console.log(game);
    console.log(scoringComplete)
    console.log(["Game Update Complete", gameUpdateComplete])      
    console.log(apiCallCount)
    console.log(new Date() - startTime)
    // try {
    //   console.log(round.prompt.taxon.default_photo.attribution)
    // } catch (error) {
    //   console.error(error);
    //   // Expected output: ReferenceError: nonExistentFunction is not defined
    //   // (Note: the exact output may be browser-dependent)
    // }
    // try {
    // console.log(round.guess.attribution)
    // } catch (error) {
    //   console.error(error);
    //   // Expected output: ReferenceError: nonExistentFunction is not defined
    //   // (Note: the exact output may be browser-dependent)
    // }
    console.log("###### END OF VARIABLE CHECK ########")
  }, [gameUpdateComplete])

  async function postResults() {
    console.log('~~~~~~~~~~~~~~~~~~~~~ CHECKING ON POSTING RESULTS  ~~~~~~~~~~~~~~~~~~~~~~')
    if (await round.roundNum > game.numRounds) {
      console.log('~~~~~~~~~~~~~~~~~~~~~ POSTING GAME RESULTS  ~~~~~~~~~~~~~~~~~~~~~~')
      
      try {
        await sql`INSERT INTO game (universe, num_rounds, score) VALUES (${game.domain}, ${game.numRounds}, ${game.gameScore});`;
      } catch (error) {
        console.log(error);
      }
  }
  }

  // async function postResultsAxios() {
  //   if (await round.roundNum > game.numRounds) {
  //     console.log('~~~~~~~~~~~~~~~~~~~~~ POSTING GAME RESULTS  ~~~~~~~~~~~~~~~~~~~~~~')
      
  //     await axios.post("http://localhost:8000/api/games/", {
  //       'universe': game.domain,
  //       'num_rounds': game.numRounds,
  //       'score': game.gameScore,
  //       })
  //     .then((res) => console.log(res));
  //   }
  // }


  const handleOnSelect = (item) => {
    setGuessValue(item)
  }

  const handleLocationSelect = (item) => {
    console.log(item)
    setGame(prevGame => ({
      ...prevGame,
      location: item.id,
      }
    ))
  }

  const handleDomainSelect = (item) => {
    console.log("Test")
    console.log(item)
    setGame(prevGame => ({
      ...prevGame,
      domain: item,
      }
    ))
  }

  function startGame() {
    console.log('~~~~~~~~~~~~~~~~~~~~~ GAME STARTING ~~~~~~~~~~~~~~~~~~~~~~')
    console.log(game)
    setConfigured(true)
  }

  async function nextRound() {
    console.log('~~~~~~~~~~~~~~~~~~~~~NEXT ROUND ~~~~~~~~~~~~~~~~~~~~~~')

    await setRound(prevRound =>  {
      return {
        ...prevRound,
        prompt : observations.results[randArr[prevRound.roundNum + 1]],
        roundNum : prevRound.roundNum + 1, 
        //guess_placed : !prevRound.guess_placed
      }
    })
    
    // var calc_rd_score = 0

    // for (const val in game.rounds) {
    //   console.log(game.rounds[val].roundScore);
    //   calc_rd_score = calc_rd_score + game.rounds[val].roundScore;
    // }
    // console.log('GAME SCORE')
    // console.log(calc_rd_score)

    // await setGame(prevGame => ({
    //   ...prevGame,
    //   gameScore : calc_rd_score
    // }))

    await setGuessPlaced(false)
    await setGuessValue({})
    await setScoringComplete(false)
    await setGameUpdateComplete(false)
    await console.log("Scoring complete set to false")
    await console.log(scoringComplete)
    await console.log(["Game Update Complete", gameUpdateComplete])
    await console.log(round.prompt)

    if ((round.roundNum + 1) > game.numRounds) {
      console.log('~~~~~~~~~~~~~~~~~~~~~ POSTING GAME RESULTS  ~~~~~~~~~~~~~~~~~~~~~~')
      
      try {
        await sql`INSERT INTO game (universe, num_rounds, score) VALUES (${game.domain}, ${game.numRounds}, ${game.gameScore});`;
      } catch (error) {
        console.log(error);
      }
    } 

    // console.log('~~~~~~~~~~~~~~~~~~~~~ GETTING SCORE RANK  ~~~~~~~~~~~~~~~~~~~~~~')
      
    // axios.get("http://localhost:8000/api/games/?limit=2")
    // .then((res) => console.log(res));
    
    // if (round.roundNum >= game.numRounds) {
    //   console.log('~~~~~~~~~~~~~~~~~~~~~ POSTING GAME RESULTS  ~~~~~~~~~~~~~~~~~~~~~~')
      
    //   axios.post("http://localhost:8000/api/games/", {
    //     'universe': game.domain,
    //     'num_rounds': game.numRounds,
    //     'score': game.gameScore,
    //     })
    //   .then((res) => console.log(res));
    // }

  }

  function newGame() {
    console.log('~~~~~~~~~~~~~~~~~~~~~STARTING NEW GAME~~~~~~~~~~~~~~~~~~~~~~')

    setGame({
      numRounds : 5,
      gameScore: 0,
      rounds : {},
      location : 1,
      domain : 47126,
    })

    setRound({
      roundNum:1,
      prompt :  null,
      prompt_ancestors :  {},
      guess : {},
      guess_ancestors: {},
      roundScore: 0,
      matchLevel: '',
    })

    setGuessed(false)
    setGuessPlaced(false)
    setObservations({})
    setApiSuccess(false)
    setGuessValue({})
    setInputValue('')
    setScore(0)
    setConfigured(false)
    setScoringComplete(false)
    setGameUpdateComplete(false)
    setSetupComplete(false)

    setRandArr(
      Array.from({ length: 20 }, (_, i) => i + 1).sort(() => Math.random() - 0.5).slice(0, 6)
    );

  }



  //console.log(round)

      return (
        <ThemeProvider theme={theme}>
        <main>
        <Header />
            {!configured ? 
            <GameSetup handleLocationSelect={handleLocationSelect} handleDomainSelect={handleDomainSelect} startGame={startGame}/> 
            : 
            !setupComplete ?
              <div>
                <Container maxWidth="lg">
                  <Stack direction="column" alignItems="center" spacing={2}>
                    <Typography variant="h5" align="center" className="header--large" paddingBottom={2} paddingTop={2}>Setting up your Game</Typography>
                    <CircularProgress color="primary" size={50} />
                  </Stack>
                </Container>
              </div>
              :
                round.roundNum > game.numRounds ?
                  <GameRecap gameScore={game.gameScore} numRounds={game.numRounds} newGame={newGame}/>
                :
                guessPlaced ?
                  gameUpdateComplete ?
                    <div>
                    <Scoring score={round.roundScore} nextRound={nextRound} correctSpecies={correctSpecies} correctSpeciesScientific={correctSpeciesScientific} correctImage = {image_url} correctCredit = {round.prompt.taxon.default_photo.attribution} guessValue={guessValue} guessPhoto={round.guess_photo} guessCredit = {round.guess.attribution}
                    matchLevel={round.matchLevel} roundNum={round.roundNum} numRounds={game.numRounds}/>
                    </div>
                    :
                    <div>
                    <Container maxWidth="lg">
                      <Stack direction="column" alignItems="center" spacing={2}>
                        <Typography variant="h5" align="center" className="header--large" paddingBottom={2} paddingTop={2}>Calculating your Score</Typography>
                        <CircularProgress color="primary" size={50} />
                      </Stack>
                    </Container>
                  </div>
                :
                <div>
                  <Guessing imageURL = {image_url} roundNum={round.roundNum} 
                  apiSuccess={apiSuccess} guessValue={guessValue} setGuessValue={setGuessValue} 
                  inputValue={inputValue} setInputValue={setInputValue} submitGuess={submitGuess} score={game.gameScore} handleOnSelect={handleOnSelect} correctSpecies={correctSpecies}/>
                </div> 
          
          }
        <Analytics />
        </main>
        </ThemeProvider>
      );
    }

export default App;